import React, {useState} from "react"
import { motion } from "framer-motion"
import { Box, Grid, Link, Flex, Text } from "theme-ui"
import { Swiper, SwiperSlide } from "swiper/react"
import { GatsbyImage } from "gatsby-plugin-image"

export function ProductSliders({ document, index }) {
const [thumbsSwiper, setThumbsSwiper] = useState(null)

  return (
    <>
      <Swiper
        spaceBetween={10}
        navigation={true}
        thumbs={{ swiper: thumbsSwiper }}
        id={"mySwiper2" + index}
        className={"mySwiper2 mySwiper2" + index}
      >
        {document.images &&
          document.images.length > 0 &&
          document.images.map(image => {
            return (
              <SwiperSlide>
                <Box
                  sx={{
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignContent:"center",
                    alignItems:"center",
                    ".gatsby-image-wrapper": {
                      width: "100%",
                      height: "auto",
                    },
                  }}
                >
                  <GatsbyImage alt="" image={image.gatsbyImageData} alt="" />
                </Box>
              </SwiperSlide>
            )
          })}
      </Swiper>
      <Swiper
        onSwiper={swiper => {
          console.log("swiper", swiper)
          return setThumbsSwiper(swiper)
        }}
        spaceBetween={10}
        slidesPerView={4}
        freeMode={true}
        watchSlidesProgress={true}
        id={"mySwiper" + index}
        className={"mySwiper mySwiper" + index}
      >
        {document.images &&
          document.images.length > 1 &&
          document.images.map(image => {
            return (
              <SwiperSlide>
                <GatsbyImage alt="" image={image.gatsbyImageData} alt="" />
              </SwiperSlide>
            )
          })}
      </Swiper>
    </>
  )
}

