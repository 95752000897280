import React, { useRef, useState } from "react"
import { useMeasure } from "../utils/use-measure"
import { motion } from "framer-motion"
import { Pager } from "./pager"
import { Box, Grid, Link, Flex, Text, Container, Heading } from "theme-ui"
import { i18nContext } from "../context/i18nContext"
import { InboundLink, OutboundLink } from "./link"
import { getColor } from "@theme-ui/color"
import themeUiTheme from "../gatsby-plugin-theme-ui"
import styled from "styled-components"

import  { ProductSliders } from "./productSliders"

import { darken, lighten } from "@theme-ui/color"

// Import Swiper styles
import "swiper/css"
import "swiper/css/free-mode"
import "swiper/css/navigation"
import "swiper/css/thumbs"

import "../assets/style/layout.css"

// import Swiper core and required modules
import SwiperCore, { FreeMode, Navigation, Thumbs } from "swiper"

const primary = getColor(themeUiTheme, "primary")
const lightenTexn = lighten("text", 0.5)

function translateItem({ x, y }) {
  return `translateX(${x}) translateY(${y})`
}

const Slider = styled(motion.div)`
  position: absolute;
  bottom: -1px;
  left: 0;
  right: 0;
  height: 4px;
  background: ${primary};
`

SwiperCore.use([FreeMode, Navigation, Thumbs])

const ProductTabs = ({ documents }) => {
  const [value, setValue] = useState(0)
  const childRefs = useRef(new Map())
  const tabListRef = useRef()
  const [slider, setSlider] = useState({ left: 0, right: 0 })
  const { bounds, ref } = useMeasure()

  

  // measure our elements
  React.useEffect(() => {
    const target = childRefs.current.get(value)
    const container = tabListRef.current
    if (target) {
      const cRect = container.getBoundingClientRect()

      // when container is `display: none`, width === 0.
      // ignore this case
      if (cRect.width === 0) {
        return
      }

      const tRect = target.getBoundingClientRect()
      const left = tRect.left - cRect.left
      const right = cRect.right - tRect.right

      setSlider({
        hasValue: true,
        left: left + 8,
        right: right + 8,
      })
    }
  }, [value, bounds])

  return (
    <div>
      <Box
        sx={{
          overflowY: "hidden",
          overflowX: "hidden",
          boxShadow: "none",
          borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
        }}
        ref={ref}
      >
        <Box
          ref={tabListRef}
          sx={{
            display: "flex",
            overflowX: "scroll",
            position: "relative",
            paddingBottom: "15px",
            mb: "-15px",
          }}
        >
          {documents.map((document, index) => (
            <motion.button
              style={{
                border: "none",
                padding: "16px",
                backgroundColor: "transparent",
                position: "relative",
              }}
              key={document.title + index}
              isActive={index === value}
              transition={{ duration: 0.1 }}
              ref={el => childRefs.current.set(index, el)}
              onClick={() => setValue(index)}
            >
              <Box
                as="span"
                variant="tabs.button.span"
                sx={{
                  "&:before": {
                    color: index === value ? "primary" : "text",
                  },
                }}
              >
                {document.title}
              </Box>
              {index === value && <Slider layoutId="underline" />}
            </motion.button>
          ))}
        </Box>
      </Box>
      <Pager value={value}>
        {documents.map((document, index) => (
          <Box
            sx={{
              width: "100%",
              padding: "16px",
              borderTop: "1px solid",
              borderColor: "lightGrey",
              "&:last-child": {
                borderColor: "lightGrey",
              },
            }}
          >
            <Grid columns={[1, 2]} gap={[2,8]} sx={{ pt: [0] }}>
              <Box>
                <Box>
                  <Heading as="h3" variant="h3" sx={{ mt: [5], mb: [0] }}>
                    {document.title}
                  </Heading>
                </Box>
                <Text
                  as="div"
                  variant="sectionTitle"
                  sx={{ py: [5] }}
                  dangerouslySetInnerHTML={{ __html: document.description }}
                />
                <Box
                  sx={{
                    overflowY: "auto",
                    table: {
                      tableLayout: "fixed",
                      height: "100%",
                    },
                    "table, tr, td": {
                      borderCollapse: "collapse",
                      "&,tr, td, th": {
                        textAlign: "left",
                        verticalAlign: "top",
                        border: "none",
                      },
                      "&": {
                        borderTop: "1px solid",
                        borderColor: "lightGrey",
                      },
                      tr: {
                        borderBottom: "1px solid",
                        borderColor: "lightGrey",
                        "td table, th table": {
                          "&,tr, td, th": {
                            border: "none",
                            td: {},
                          },
                        },
                        "th, td": {
                          p: {
                            m: 0,
                          },
                          em: {
                            color: "primary",
                            fontStyle: "normal",
                          },
                          'span[style*="text-decoration: line-through"]': {
                            fontWeight: "normal",
                            color: lightenTexn,
                            textDecoration: "unset!important",
                          },
                          px: [3],
                          py: [2],
                        },
                      },
                    },
                  }}
                  dangerouslySetInnerHTML={{ __html: document.productTable }}
                />
              </Box>
              <Box>
                {index === value && (
                  <ProductSliders document={document} index={index} />
                )}
              </Box>
            </Grid>
          </Box>
        ))}
      </Pager>
    </div>
  )
}

export default ProductTabs
