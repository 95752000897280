import React, { useState } from "react"
import { graphql } from "gatsby"
import {
  Box,
  Container,
  Grid,
  Heading,
  Text,
  Flex,
  AspectRatio,
} from "@theme-ui/components"
import { Link } from "../components/link"
import loadable from "@loadable/component"
import Layout from "../components/layout"
import { getPagePath, getProductPath, getCategoryPath } from "../utils/path"
import { Link as GatsbyLink } from "gatsby"
import { HelmetDatoCms } from "gatsby-source-datocms"
import PageHero from "./pageHero"
import { GatsbyImage } from "gatsby-plugin-image"
import ProductThumb from "../components/productThumb.js"
import { InboundLink } from "../components/link"
import { useFavicon } from "../hooks/useFavicon"
import ImageAndText from "../components/blocks/imageAndText"
import ContactFooterSection from "../components/blocks/contactFooterSection"
import ProductTabs from "../components/productTabs"
import { darken, lighten } from "@theme-ui/color"

// const LocationsMap = loadable(
//   () => import("../components/blocks/locationMap"),
//   { ssr: false }
// )

const Page = ({
  data: { page, categories, products, site, articles, contactFooter },
}) => {
  const favicon = useFavicon().site.faviconMetaTags
  const pageAllSlugLocales = page._allSlugLocales.sort(function (a, b) {
    return site.locales.indexOf(a.locale) - site.locales.indexOf(b.locale)
  })

  const [hasProducts, setHasProducts] = useState(
    products.nodes.find(product => page.title == product.category.title) ? 1 : 0
  )

  const categoryProducts = products.nodes.map(product => {
    if (page.title == product.category.title) return product
  })
  console.log(categoryProducts)

  const i18nPaths = pageAllSlugLocales.map(locale => {
    return {
      locale: locale.locale,
      value: getPagePath(page, locale.locale),
    }
  })
  const lightenTexn = lighten("text", 0.5)

  return (
    <Layout locale={page.locale} i18nPaths={i18nPaths}>
      <HelmetDatoCms seo={page.seoMetaTags} favicon={favicon}>
        <html lang={page.locale} />
      </HelmetDatoCms>
      <PageHero page={page} image={page.heroImage} />
      <Box>
        {page.content &&
          page.content.map(block => (
            <Box as="section" key={block.id}>
              {block.model.apiKey === "image_and_text" && (
                <ImageAndText
                  noTitle={true}
                  kicker={block.content.label}
                  title={block.content.title}
                  richContent={block.content}
                  image={block.content.image}
                  rightAligned={block.rightAlignment}
                  fullWidth={block.fullWidth}
                  imageMargin={block.imageMargin}
                />
              )}
              {block.model.apiKey === "product_table" && (
                <Container variant="xl" id={block.id}>
                  <Box sx={{ display: "none" }}>
                    <GatsbyLink to={`/${page.slug}#${block.id}`}>
                      Vai direttamente alla tabella
                    </GatsbyLink>
                  </Box>
                  <Box
                    sx={{
                      px: [3, 0],
                      overflowX: "auto",
                      py: [7],
                      table: {
                        tableLayout: "fixed",
                        height: "100%",
                      },
                      "table, tr, td": {
                        borderCollapse: "collapse",
                        "&,tr, td, th": {
                          textAlign: "left",
                          verticalAlign: "top",
                          border: "none",
                        },
                        "&": {
                          borderTop: "1px solid",
                          borderColor: "lightGrey",
                        },
                        tr: {
                          height: "0!important",
                          borderBottom: "1px solid",
                          borderColor: "lightGrey",
                          "td table, th table": {
                            "&,tr, td, th": {
                              border: "none",
                              td: {},
                            },
                          },

                          "th, td": {
                            p: {
                              m: 0,
                            },
                            em: {
                              color: "primary",
                              fontStyle: "normal",
                            },
                            'span[style*="text-decoration: line-through"]': {
                              fontWeight: "normal",
                              color: lightenTexn,
                              textDecoration: "unset!important",
                            },
                            px: [3],
                            py: [2],
                          },
                        },
                      },
                    }}
                    dangerouslySetInnerHTML={{ __html: block.table }}
                  />
                </Container>
              )}
            </Box>
          ))}
      </Box>
      <Box>
        <Container variant="xl" id={categoryProducts[0].id}>
          <Box sx={{ display: "none" }}>
            <GatsbyLink to={`/${page.slug}#${categoryProducts[0].id}`}>
              Vai direttamente alla tabella
            </GatsbyLink>
          </Box>
          <Box sx={{ py: [6] }}>
            <ProductTabs documents={categoryProducts} />
            <Grid columns={[1, 2, 3, 3]} gap={[2, 2, 2, 4]}>
              {products.nodes.map(product => {
                page.title == product.category.title && (
                  <ProductThumb product={product} category={page.title} />
                )
              })}
            </Grid>
          </Box>
        </Container>
      </Box>
      <ContactFooterSection
        kicker={contactFooter.kicker}
        image={contactFooter.background}
        title={contactFooter.title}
        link={contactFooter.contactPageLink}
      />
    </Layout>
  )
}

const List = props => {
  return (
    <Flex
      {...props}
      sx={{
        flexDirection: "column",
        margin: 0,
        padding: 0,
        mb: 0,
        listStyle: "none",
        a: {
          textDecoration: "none",
          "&:hover": {
            textDecoration: "underline",
          },
        },
      }}
      as="ul"
    />
  )
}

const Item = props => {
  return <Box {...props} as="li" sx={{ mb: 0, "&:last-child": { mb: 0 } }} />
}

export default Page

export const query = graphql`
  query ProductCategoryQuery($id: String!, $locale: String!) {
    site: datoCmsSite {
      locales
    }
    page: datoCmsProductCategory(id: { eq: $id }) {
      id
      locale
      title
      slug
      root
      body {
        value
      }
      model {
        apiKey
      }
      ...AllProductCategorySlugLocales
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      image {
        gatsbyImageData(
          width: 1920
          placeholder: NONE
          forceBlurhash: false
          imgixParams: {
            blendColor: "#212C30"
            blendMode: "multiply"
            blendAlpha: 60
          }
        )
      }
      heroImage {
        gatsbyImageData(
          placeholder: NONE
          forceBlurhash: false
          imgixParams: {
            blendColor: "#212C30"
            blendMode: "multiply"
            blendAlpha: 60
          }
        )
      }
      content {
        ... on DatoCmsImageAndText {
          id
          imageMargin
          rightAlignment
          content {
            ... on DatoCmsRichContent {
              ...RichContent
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsProductTable {
          id
          table
          model {
            apiKey
          }
        }
      }
    }
    categories: allDatoCmsProductCategory(
      filter: { slug: { ne: null }, locale: { eq: $locale } }
      sort: { fields: position, order: ASC }
    ) {
      nodes {
        id
        title
        locale
        ...ProductCategoryPageDetails
        model {
          apiKey
        }
      }
    }
    products: allDatoCmsProduct(
      filter: { category: { id: { eq: $id } }, locale: { eq: $locale } }
      sort: { fields: position, order: ASC }
    ) {
      nodes {
        id
        ...AllProductSlugLocales
        title
        slug
        position
        description
        locale
        category {
          id
          title
          locale
          ...ProductCategoryPageDetails

          model {
            apiKey
          }
        }
        images {
          gatsbyImageData(width: 1920, placeholder: NONE, forceBlurhash: false)
        }
        productTable
      }
    }
    contactFooter: datoCmsContactFooterSection(locale: { eq: $locale }) {
      id
      kicker
      title
      model {
        apiKey
      }
      background {
        gatsbyImageData(
          width: 1920
          placeholder: NONE
          forceBlurhash: false
          imgixParams: {
            blendColor: "#212C30"
            blendMode: "multiply"
            blendAlpha: 70
          }
        )
      }
      contactPageLink {
        ... on DatoCmsInternalLink {
          id: originalId
          anchor
          locale

          model {
            apiKey
          }
          link {
            ... on DatoCmsBlogPage {
              ...BlogDetails
            }
            ... on DatoCmsPage {
              ...PageDetails
              ...PageTreeParent
              ...AllSlugLocales
            }
            ... on DatoCmsArticle {
              ...ArticleDetails
              ...ArticleAllSlugLocales
            }
            ... on DatoCmsArticleCategory {
              ...ArticleCategoryDetails
              ...ArticleCategoryAllSlugLocales
            }
          }
        }
      }
    }
  }

  fragment ProductCategoryPageDetails on DatoCmsProductCategory {
    id
    locale
    title
    slug
    root
    model {
      apiKey
    }
    image {
      gatsbyImageData(
        width: 1920
        placeholder: NONE
        forceBlurhash: false
        imgixParams: {
          blendColor: "#212C30"
          blendMode: "multiply"
          blendAlpha: 60
        }
      )
    }
    heroImage {
      gatsbyImageData(
        width: 1920
        placeholder: NONE
        forceBlurhash: false
        imgixParams: {
          blendColor: "#212C30"
          blendMode: "multiply"
          blendAlpha: 60
        }
      )
    }
  }

  fragment AllProductCategorySlugLocales on DatoCmsProductCategory {
    _allSlugLocales {
      value
      locale
    }
  }
`
